import { capturePaymentInfo } from '../components/members/api';

export type CashNetPaymentInfo = {
  productPurchaseId: string;
  eventPurchaseId: string;
  batchno: string;
  tx: string;
  custcode: string;
  ref3val1: string;
};

export const processCashNetPaymentInfo = async (
  cashnetPaymentInfo: CashNetPaymentInfo
) => {
  try {
    return await capturePaymentInfo(cashnetPaymentInfo);
  } catch (err) {
    console.error(err);
  }
};

export const detectCashNetPayment = (url: string) => {
  const params = ['batchno', 'tx', 'custcode', 'ref3val1'];
  return params.every(param => url.includes(param));
};
